// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// Import the functions you need from the SDKs you need

export const environment = {
  firebase: {
    projectId: 'rdsprobim',
    appId: '1:921773614848:web:6d50396f2737a8f24775e5',
    storageBucket: 'rdsprobim.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyA8x_zrEvhpDHuIYtwxKTqCwHPN9YivQm8',
    authDomain: 'rdsprobim.firebaseapp.com',
    messagingSenderId: '921773614848',
    measurementId: 'G-8HQQKWGLRS',
  },
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyA8x_zrEvhpDHuIYtwxKTqCwHPN9YivQm8",
    authDomain: "fire.bimgames.com",
    projectId: "rdsprobim",
    storageBucket: "rdsprobim.appspot.com",
    messagingSenderId: "921773614848",
    appId: "1:921773614848:web:6d50396f2737a8f24775e5",
    measurementId: "G-8HQQKWGLRS"
  }
};

/*
authDomain: "rdsprobim.firebaseapp.com"
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
