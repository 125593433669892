import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule} from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { File } from '@ionic-native/file/ngx';


import { FileOpener } from '@ionic-native/file-opener/ngx';

import { NgxView360Module } from 'ngx-view360';

import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
//import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NotificationsPage } from './notifications/notifications.page';

import { WebViewerComponent } from './webviewer/webviewer.component';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { UploadManagerComponent} from './upload-manager/upload-manager.component';
import { DropzoneDirective } from './dropzone.directive';

import { UploadTaskComponent } from './upload-task/upload-task.component';

import { Clipboard } from '@ionic-native/clipboard/ngx';

@NgModule({
  declarations: [
  AppComponent,
  WebViewerComponent, 
  NotificationsPage,
  UploadManagerComponent,
  UploadTaskComponent, 
  DropzoneDirective, 
  
],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    NgxView360Module,
    NgxSliderModule,

    

    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
   

  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy, },File,FileOpener,
   // LocalNotifications,
   //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,},
  [Clipboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }]
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}

