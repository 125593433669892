import { Component, OnInit } from '@angular/core';
import { GenericCRUDService } from '../services/gencrud.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {
  CompanyRef: string;
  notifications: any[] = [];
  CurrentUser: string;
  constructor(private genSvc: GenericCRUDService) {}

  ngOnInit() {
    this.CurrentUser = this.genSvc.getUserEmail();
    this.CompanyRef = this.genSvc.getUserCompanyRef();
    //const user = JSON.parse(localStorage.getItem('UserDeets'));
    this.genSvc
      .getAllWhere(
        'Companies/' + this.CompanyRef + '/Notifications',
        'UserRef',
        '==',
        this.CurrentUser
      )
      .subscribe((result) => {
        this.notifications = result;
        
      });
  }
}
