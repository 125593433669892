import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from './guards/auth.guard';
import { AuthenticationGuard } from './shared/authentication.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home-page/home-page.module').then( m => m.HomePagePageModule),
    canActivate: [AuthenticationGuard]
  },

  //test
  // {
  //   path: '',
  //   loadChildren: () => import('./user/login/login.module').then( m => m.LoginPageModule),
  // },
  {
    path: 'login',
    loadChildren: () => import('./user/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register/:id',
    loadChildren: () => import('./user/register-user/register-user.module').then( m => m.RegisterUserPageModule),

  },
  // {
  //   path: '',

  //   pathMatch: 'full',
  //   canActivate:  [AuthGuard]
  // },


  {
    path: 'gg',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canActivate: [AuthenticationGuard]
  },


  {
    path: 'companies',
    loadChildren: () => import('./company/companies/companies.module').then( m => m.CompaniesPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'create-company',
    loadChildren: () => import('./company/create-company/create-company.module').then( m => m.CreateCompanyPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'update-company',
    loadChildren: () => import('./company/update-company/update-company.module').then( m => m.UpdateCompanyPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'projects',
    loadChildren: () => import('./project/projects/projects.module').then( m => m.ProjectsPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'create-project',
    loadChildren: () => import('./project/create-project/create-project.module').then( m => m.CreateProjectPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'update-project/:id',
    loadChildren: () => import('./project/update-project/update-project.module').then( m => m.UpdateProjectPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'create-user',
    loadChildren: () => import('./user/create-user/create-user.module').then( m => m.CreateUserPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'update-user/:UserRef',
    loadChildren: () => import('./user/update-user/update-user.module').then( m => m.UpdateUserPageModule),
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'users',
    loadChildren: () => import('./user/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-department',
    loadChildren: () => import('./department/create-department/create-department.module').then( m => m.CreateDepartmentPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-department',
    loadChildren: () => import('./department/update-department/update-department.module').then( m => m.UpdateDepartmentPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'departments',
    loadChildren: () => import('./department/departments/departments.module').then( m => m.DepartmentsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-discipline',
    loadChildren: () => import('./discipline/create-discipline/create-discipline.module').then( m => m.CreateDisciplinePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-discipline',
    loadChildren: () => import('./discipline/update-discipline/update-discipline.module').then( m => m.UpdateDisciplinePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'disciplines',
    loadChildren: () => import('./discipline/disciplines/disciplines.module').then( m => m.DisciplinesPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-office',
    loadChildren: () => import('./office/create-office/create-office.module').then( m => m.CreateOfficePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-office',
    loadChildren: () => import('./office/update-office/update-office.module').then( m => m.UpdateOfficePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'offices',
    loadChildren: () => import('./office/offices/offices.module').then( m => m.OfficesPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-worker',
    loadChildren: () => import('./worker/create-worker/create-worker.module').then( m => m.CreateWorkerPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-worker',
    loadChildren: () => import('./worker/update-worker/update-worker.module').then( m => m.UpdateWorkerPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'workers',
    loadChildren: () => import('./worker/workers/workers.module').then( m => m.WorkersPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-member',
    loadChildren: () => import('./member/add-member/add-member.module').then( m => m.AddMemberPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'members',
    loadChildren: () => import('./member/members/members.module').then( m => m.MembersPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-crew',
    loadChildren: () => import('./crew/create-crew/create-crew.module').then( m => m.CreateCrewPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-crew',
    loadChildren: () => import('./crew/update-crew/update-crew.module').then( m => m.UpdateCrewPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'crews',
    loadChildren: () => import('./crew/crews/crews.module').then( m => m.CrewsPageModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'create-externalusers',
    loadChildren: () => import('./externaluser/create-externalusers/create-externalusers.module').then( m => m.CreateExternalusersPageModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'update-externalusers/:id',
    loadChildren: () => import('./externaluser/update-externalusers/update-externalusers.module').then( m => m.UpdateExternalusersPageModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'create-externalcompany',
    loadChildren: () => import('./externalcompany/create-externalcompany/create-externalcompany.module').then( m => m.CreateExternalcompanyPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-externalcompany/:id',
    loadChildren: () => import('./externalcompany/update-externalcompany/update-externalcompany.module').then( m => m.UpdateExternalcompanyPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'externalcompanies',
    loadChildren: () => import('./externalcompany/externalcompanies/externalcompanies.module').then( m => m.ExternalcompaniesPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'externalusers',
    loadChildren: () => import('./externaluser/externalusers/externalusers.module').then( m => m.ExternalusersPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'company-dashboard',
    loadChildren: () => import('./Dashboards/Company-Dir/company-dashboard/company-dashboard.module').then( m => m.CompanyDashboardPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-companyuser/:id',
    loadChildren: () => import('./Dashboards/Company-Dir/add-companyuser/add-companyuser.module').then( m => m.AddCompanyuserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'view-externalcompany/:id',
    loadChildren: () => import('./Dashboards/Company-Dir/ExternalCompany-Dir/view-externalcompany/view-externalcompany.module').then( m => m.ViewExternalcompanyPageModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'add-projectuser/:id',
    loadChildren: () => import('./Dashboards/Project-Dir/add-projectuser/add-projectuser.module').then( m => m.AddProjectuserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'project-dashboard/:id',
    loadChildren: () => import('./Dashboards/Project-Dir/project-dashboard/project-dashboard.module').then( m => m.ProjectDashboardPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-projectcompany/:id',
    loadChildren: () => import('./Dashboards/Project-Dir/add-projectcompany/add-projectcompany.module').then( m => m.AddProjectcompanyPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-projectuser/:id/:id1',
    loadChildren: () => import('./Dashboards/Project-Dir/update-projectuser/update-projectuser.module').then( m => m.UpdateProjectuserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-externaluser/:id',
    loadChildren: () => import('./Dashboards/Company-Dir/ExternalCompany-Dir/add-externaluser/add-externaluser.module').then( m => m.AddExternaluserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-companyuser/:userref/:companyref',
    loadChildren: () => import('./Dashboards/Company-Dir/ExternalCompany-Dir/update-companyuser/update-companyuser.module').then( m => m.UpdateCompanyuserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'project-companyuser/:compref/:projref',
    loadChildren: () => import('./Dashboards/Project-Dir/Project-Company/project-companyuser/project-companyuser.module').then( m => m.ProjectCompanyuserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-projcompuser/:compref/:projref',
    loadChildren: () => import('./Dashboards/Project-Dir/Project-Company/add-projcompuser/add-projcompuser.module').then( m => m.AddProjcompuserPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'document-management',
    loadChildren: () => import('./Documents/document-management/document-management.module').then( m => m.DocumentManagementPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'view-document/:ProjRef',
    loadChildren: () => import('./Documents/view-document/view-document.module').then( m => m.ViewDocumentPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-folder',
    loadChildren: () => import('./Documents/add-folder/add-folder.module').then( m => m.AddFolderPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-folderlevels',
    loadChildren: () => import('./Documents/add-folderlevels/add-folderlevels.module').then( m => m.AddFolderlevelsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-rfi/:ProjRef',
    loadChildren: () => import('./rfi/create-rfi/create-rfi.module').then( m => m.CreateRfiPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'view-transmitals/',
    loadChildren: () => import('./Documents/view-transmitals/view-transmitals.module').then( m => m.ViewTransmitalsPageModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'rfi-response/:RfiRef',
    loadChildren: () => import('./rfi/rfi-response/rfi-response.module').then( m => m.RfiResponsePageModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'create-snag/:ProjRef',
    loadChildren: () => import('./snag/create-snag/create-snag.module').then( m => m.CreateSnagPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'snags/:id',
    loadChildren: () => import('./snag/snags/snags.module').then( m => m.SnagsPageModule),
    canActivate: [AuthenticationGuard]

      },





  {
    path: 'snag-response/:SnagRef',
    loadChildren: () => import('./snag/snag-response/snag-response.module').then( m => m.SnagResponsePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'rfis/:id',
    loadChildren: () => import('./rfi/rfis/rfis.module').then( m => m.RfisPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'incidents/:id',
    loadChildren: () => import('./incidentz/incidents/incidents.module').then( m => m.IncidentsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-incident/:ProjRef',
    loadChildren: () => import('./incidentz/create-incident/create-incident.module').then( m => m.CreateIncidentPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'view-incident/:incref/:projref',
    loadChildren: () => import('./incidentz/view-incident/view-incident.module').then( m => m.ViewIncidentPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-injuryrecord',
    loadChildren: () => import('./incidentz/create-injuryrecord/create-injuryrecord.module').then( m => m.CreateInjuryrecordPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'create-task/:ProjRef',
    loadChildren: () => import('./taskz/create-task/create-task.module').then( m => m.CreateTaskPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'view-task/:taskref/:projref',
    loadChildren: () => import('./taskz/view-task/view-task.module').then( m => m.ViewTaskPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'tasks/:projref',
    loadChildren: () => import('./taskz/tasks/tasks.module').then( m => m.TasksPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'audits/:projref',
    loadChildren: () => import('./audits/audits/audits.module').then( m => m.AuditsPageModule)
  },
//////////////////////////////////////////////////////
  {
    path: 'view-audit/:AuditRef/:ProjRef',
    loadChildren: () => import('./audits/view-audit/view-audit.module').then( m => m.ViewAuditPageModule)
  },
{
  path: 'view-ticket/:AuditRef/:ProjRef',
  loadChildren: () => import('./FMmodule/view-audit/view-audit.module').then( m => m.ViewAuditPageModule)
},

//////////////////////////////////////////

  {
    path: 'complete-auditquestions',
    loadChildren: () => import('./audits/complete-auditquestions/complete-auditquestions.module').then( m => m.CompleteAuditquestionsPageModule)
  },
  {
    path: 'start-audit/:ProjRef',
    loadChildren: () => import('./audits/start-audit/start-audit.module').then( m => m.StartAuditPageModule)
  },
  {
    path: 'upload-audits/:projref',
    loadChildren: () => import('./audits/upload-audits/upload-audits.module').then( m => m.UploadAuditsPageModule)
  },
  {
    path: 'complete-noncompliantitem/:AuditResponseRef/:AuditRef',
    loadChildren: () => import('./audits/complete-noncompliantitem/complete-noncompliantitem.module').then( m => m.CompleteNoncompliantitemPageModule)
  },
  {
    path: 'view-versions',
    loadChildren: () => import('./Documents/view-versions/view-versions.module').then( m => m.ViewVersionsPageModule)
  },
  {
    path: 'request-extension',
    loadChildren: () => import('./audits/request-extension/request-extension.module').then( m => m.RequestExtensionPageModule)
  },

  ////////////////////////////////////////////////////////////////////////////////////
  {
  path: 'fm-request-extension',
  loadChildren: () => import('./FMmodule/request-extension/fm-request-extension.module').then( m => m.RequestExtensionPageModule)
  },

  {
    path: 'fm-request-extensio-approval',
    loadChildren: () => import('./FMmodule/request-extension-approval/fm-request-extension-approval.module').then( m => m.RequestExtensionPageModuleApproval)
    },
///////////////////////////////////////////////////////////////////////////////////////////

  {
    path: 'submitals/:id',
    loadChildren: () => import('./submittals/submitals/submitals.module').then( m => m.SubmitalsPageModule)
  },
  {
    path: 'approve-extension',
    loadChildren: () => import('./audits/approve-extension/approve-extension.module').then( m => m.ApproveExtensionPageModule)
  },
  {
    path: 'approve-noncompliance/:AuditRef/:ItemRef',
    loadChildren: () => import('./audits/approve-noncompliance/approve-noncompliance.module').then( m => m.ApproveNoncompliancePageModule)
  },
  {
    path: 'submittal-settings/:ProjRef/:Settings',
    loadChildren: () => import('./submittals/submittal-settings/submittal-settings.module').then( m => m.SubmittalSettingsPageModule)
  },
  {
    path: 'create-submittal/:ProjRef',
    loadChildren: () => import('./submittals/create-submittal/create-submittal.module').then( m => m.CreateSubmittalPageModule)
  },
  {
    path: 'submittal-response/:SubmittalRef/:ProjRef',
    loadChildren: () => import('./submittals/submittal-response/submittal-response.module').then( m => m.SubmittalResponsePageModule)
  },
  {
    path: 'submittal-revision/:SubRef/:ProjectRef',
    loadChildren: () => import('./submittals/submittal-revision/submittal-revision.module').then( m => m.SubmittalRevisionPageModule)
  },
  {
    path: 'view-assigneditem/:AuditRef/:ProjRef',
    loadChildren: () => import('./audits/view-assigneditem/view-assigneditem.module').then( m => m.ViewAssigneditemPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./user/user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'forward-submittal/:SubmittalRef/:ProjRef/:ForRef',
    loadChildren: () => import('./submittals/forward-submittal/forward-submittal.module').then( m => m.ForwardSubmittalPageModule)
  },
  {
    path: 'view-forwardedsubmittals/:SubmittalRef/:ProjRef',
    loadChildren: () => import('./submittals/view-forwardedsubmittals/view-forwardedsubmittals.module').then( m => m.ViewForwardedsubmittalsPageModule)
  },
  {
    path: 'replied-subfor',
    loadChildren: () => import('./submittals/replied-subfor/replied-subfor.module').then( m => m.RepliedSubforPageModule)
  },
  {
    path: 'home-page',
    loadChildren: () => import('./home-page/home-page.module').then( m => m.HomePagePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'project-select/:id',
    loadChildren: () => import('./project-select/project-select.module').then( m => m.ProjectSelectPageModule)
  },
  {
    path: 'project-company-users/:projref',
    loadChildren: () => import('./project/project-company-users/project-company-users.module').then( m => m.ProjectCompanyUsersPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./user/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'company-overview',
    loadChildren: () => import('./companyOverview/company-overview/company-overview.module').then( m => m.CompanyOverviewPageModule)
  },
  {
    path: 'update-maincompany/:compref',
    loadChildren: () => import('./companyOverview/update-maincompany/update-maincompany.module').then( m => m.UpdateMaincompanyPageModule)
  },
  {
    path: 'audit-report/:AuditRef/:ProjRef',
    loadChildren: () => import('./audits/audit-report/audit-report.module').then( m => m.AuditReportPageModule)
  },
  {
    path: 'facilities-management',
    loadChildren: () => import('./FMmodule/facilities-management/facilities-management.module').then( m => m.FacilitiesManagementPageModule)
  },
  {
    path: 'add-ticket/:buildingref',
    loadChildren: () => import('./FMmodule/add-ticket/add-ticket.module').then( m => m.AddTicketPageModule)
  },
  {
    path: 'ticket-overview/:buildingref',
    loadChildren: () => import('./FMmodule/ticket-overview/ticket-overview.module').then( m => m.TicketOverviewPageModule)
  },
  {
    path: 'add-building',
    loadChildren: () => import('./FMmodule/add-building/add-building.module').then( m => m.AddBuildingPageModule)
  },
  {
    path: 'view-tickets/:buildingref',
    loadChildren: () => import('./FMmodule/view-tickets/view-tickets.module').then( m => m.ViewTicketsPageModule)
  },
  {
    path: 'ticket-response/:id1/:id2',
    loadChildren: () => import('./FMmodule/ticket-response/ticket-response.module').then( m => m.TicketResponsePageModule)
  },
  {
    path: 'view-building-users/:id',
    loadChildren: () => import('./FMmodule/view-building-users/view-building-users.module').then( m => m.ViewBuildingUsersPageModule)
  },
  {
    path: 'add-building-user',
    loadChildren: () => import('./FMmodule/add-building-user/add-building-user.module').then( m => m.AddBuildingUserPageModule)
  },
  {
    path: 'update-building-user',
    loadChildren: () => import('./FMmodule/update-building-user/update-building-user.module').then( m => m.UpdateBuildingUserPageModule)
  },
  {
    path: 'update-building/:id',
    loadChildren: () => import('./FMmodule/update-building/update-building.module').then( m => m.UpdateBuildingPageModule)
  },
  {
    path: 'assigned-tickets',
    loadChildren: () => import('./FMmodule/assigned-tickets/assigned-tickets.module').then( m => m.AssignedTicketsPageModule)
  },
  {
    path: 'add-isomodal',
    loadChildren: () => import('./companyOverview/add-isomodal/add-isomodal.module').then( m => m.AddISOModalPageModule)
  },
  {
    path: 'view-transmitals',
    loadChildren: () => import('./Documents/view-transmitals/view-transmitals.module').then( m => m.ViewTransmitalsPageModule)
  },
  {
    path: 'view-transmitals',
    loadChildren: () => import('./view-transmitals/view-transmitals.module').then( m => m.ViewTransmitalsPageModule)
  },
  {
    path: 'add-permission',
    loadChildren: () => import('./Documents/add-permission/add-permission.module').then( m => m.AddPermissionPageModule)
  },
  {
    path: 'add-roles',
    loadChildren: () => import('./Documents/add-roles/add-roles.module').then( m => m.AddRolesPageModule)
  },
  {
    path: 'archive360-view/:ProjRef/:ProjectRef',
    loadChildren: () => import('./archive360/archive360-view/archive360-view.module').then( m => m.Archive360ViewPageModule)
  },

  {
    path: 'archive360-add/:ProjRef',
    loadChildren: () => import('./archive360/archive360-add/archive360-add.module').then( m => m.Archive360AddPageModule)
  },
  {
    path: 'archive360-dash/:ProjRef',
    loadChildren: () => import('./archive360/archive360-dash/archive360-dash.module').then( m => m.Archive360DashPageModule)
  },
  {
    path: 'add-projectgroup',
    loadChildren: () => import('./Dashboards/Project-Dir/add-projectgroup/add-projectgroup.module').then( m => m.AddProjectgroupPageModule)
  },
  {
    path: 'update-projectgroup',
    loadChildren: () => import('./Dashboards/Project-Dir/update-projectgroup/update-projectgroup.module').then( m => m.UpdateProjectgroupPageModule)
  },

  {
    path: 'archive360-pdf/:CompanyRef/:ProjRef/:AreaRef/:idRef',
    loadChildren: () => import('./archive360/archive360-pdf/archive360-pdf.module').then( m => m.Archive360PdfPageModule)
  },
  {
    path: 'archive360-floorplan',
    loadChildren: () => import('./archive360/archive360-floorplan/archive360-floorplan.module').then( m => m.Archive360FloorplanPageModule)
  },


  {

    path: 'archive360-tab',
    loadChildren: () => import('./archive360/archive360-tab/archive360-tab.module').then( m => m.Archive360TabPageModule)
  },  {
    path: 'archive360-planlinks',
    loadChildren: () => import('./archive360/archive360-planlinks/archive360-planlinks.module').then( m => m.Archive360PlanlinksPageModule)
  },















































];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
