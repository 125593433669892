import { Component, OnInit,Input } from '@angular/core';


@Component({
    selector: 'upload-manager',
    templateUrl: './upload-manager.component.html',
    styleUrls: ['./upload-manager.component.css']
})
export class UploadManagerComponent implements OnInit {

    @Input() archive360:boolean;
    @Input() AreaRef:string;

    @Input() IndexSum:number;

    @Input() ProjectRef:string;
    @Input() FileConvention:string;
    @Input() EnableFile:boolean;
    
    @Input() fullname:string;
    @Input() email:string;
    @Input() Convname:string;
    @Input() Foldername:string;
    @Input() DocPath:string;
    
    isHovering: boolean;
    files: File[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log('uploadManager adding file: ', files.item(i));
            this.files.push(files.item(i));
        }
    }

    
 

}
