



import { Injectable } from '@angular/core';

import { ReplaySubject, Subject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { AuthDetails } from '../shared/enums';
import { FBUser } from '../models/fbuser';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { GenericCRUDService } from './gencrud.service';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';



@Injectable({
  providedIn: 'root'
})
export class AccountService {
  storagepath:string;
  userData: any;
  uid: string;
  private _storage: Storage | null = null;
  private currentUserSource = new ReplaySubject<any>(1);
  currentUser$ = this.currentUserSource.asObservable();

  private UserDetailsstorage: Storage | null = null;
  UserDeets: any;
  CompanyRef: string;
  CompanyStorage: string;
 // UserDetails:any ={};
  UserDetails = JSON.parse(localStorage.getItem('UserDeets'));


  constructor(private genService:GenericCRUDService, private storage: Storage,private afAuth:AngularFireAuth

    ) {
    this.init();


  }

  async init() {

    const storage = await this.storage.create();
    this._storage = storage;
  }

  //----------------------------------Account-----------------------------------------------------------
  async signIn(email, password) {

    //THE RIGHT WAY
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password).then(async res => {
         await this.getCurrentUserDetails(res.user.uid);
         
            resolve(res);
          },
          err => reject(err));
    });




  }


  async test(email, password) {

    //THE RIGHT WAY
 
      this.afAuth.signInWithEmailAndPassword(email, password).then(res => {
          this.getCurrentUserDetails(res.user.uid);
            return res.user
          });
          
  




  }



  setUserDetails = (email: string, password: string, tokenExpiryTime: string) => {


    const epochTime = Date.parse(tokenExpiryTime);

    this._storage?.set(AuthDetails.email, email);
    this._storage.set(AuthDetails.password, password);
    this._storage.set(AuthDetails.tokenExpiryTime, epochTime);

  };

  async getCurrentUserDetails(userRef: string) {

var deetz:any[];
    this.genService.getAllWhere("users",'MainUserRef', '==', userRef).subscribe(res =>{
      deetz = res;

      this.UserDeets  = deetz.find(obj => obj.MainUserRef == userRef);
      if(this.UserDeets.Permission == "Admin"){
        this.genService.GenPopP = "Admin";
            }else{
              this.genService.GenPopP =  "General";
              this.genService.showMenu = true;
            }
this.currentUser$ = this.UserDeets;
      localStorage.setItem('UserDeets', JSON.stringify(this.UserDeets));
      this.genService.displayMenuItems();

    },(error) =>{
      console.error(error);

    },() =>{


      //const compRef = this.genService.getUserCompanyRef();
      this.getStorage(  this.UserDeets.CompanyRef);


    })





  }

  async getCurrentCompanyStorage(user: any) {

    //   const table1 = doc(this.firestore, 'Companies/' + user.CompanyRef);
    //   //
    // var doccy = getDoc(table1)
    //
    // this.CompanyStorage
  }

  async register(email: string, password: string, user: FBUser) {
    const auth = getAuth();
var data = true;
     createUserWithEmailAndPassword(auth, email, password).then(res =>{
      user.UserRef = res.user.uid;
      this.createFireStoreUser(user);

    },(error)=>{
      data = false
    });

    //this.sendEmailVerification(result);
return data;

  }

  async getStorage(ref:string){

    var path ='Companies';
    this.genService.get(path,ref).subscribe(res => {
  var company:any ={};
  company = res.data();
  this.storagepath = company.Name;
    },(error)=>{

    },() => {

    })
  }

  async createFireStoreUser(user: any) {
    var dat:any = {};
    var mod:any ={};
    var path1= 'Companies/' + user.CompanyRef + '/Users';
    
    this.genService.getAllWhere(path1,'UserEmail','==',user.UserEmail).subscribe(res => {


      if(res[0]){

dat = res[0];
mod.MainUserRef = user.UserRef;
mod.CompanyRef = user.CompanyRef;
dat.MainUserRef = user.UserRef;
dat.CompanyRef = user.CompanyRef;
this.genService.update(path1,dat.id,mod);
this.createFireStoreGlobalUser(dat);
      }else{

        var path= 'Companies/' + user.CompanyRef + '/ExternalUsers';
        this.genService.getAllWhere(path,'UserEmail','==',user.UserEmail).subscribe(res =>{

          if(res[0]){
            dat = res[0];

mod.MainUserRef = user.UserRef;
mod.CompanyRef = user.CompanyRef;
dat.MainUserRef = user.UserRef;
dat.MainCompany = dat.CompanyRef;
dat.CompanyRef = user.CompanyRef;
dat.Permission = "Viewer";
this.genService.create(path,mod).subscribe(res =>{
  this.createFireStoreGlobalUser(dat);
})
          }
        })


      }
    })
  }

  async createFireStoreGlobalUser(user: any) {


    var path = 'users';
    this.genService.create(path,user)
    // addDoc(table, user);

  }


  // async sendEmailVerification(result: any) {
  //   await sendEmailVerification(result);

  // }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout() {
    // const auth = getAuth();
    // await signOut(auth);
    localStorage.removeItem('userz');
    localStorage.removeItem('UserDeets');
    localStorage.removeItem('user');
    this.genService.showMenu = false;
    return "ok";
  }


  // get isLoggedIn(): boolean {
  //   return getAuth().currentUser != null ? true : false;
  // }


  getCompanyRef(): string{
    const x = JSON.parse(localStorage.getItem('UserDeets'));
    return x.CompanyRef;
  }


  async resetPassword(email: string): Promise<void> {
    await this.afAuth.sendPasswordResetEmail(email).then(() => {

    }).catch((error) => {
       //this.notifier.showError(error.message);
    });
 }




























}
