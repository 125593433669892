import { Component, ViewChild, OnInit, ElementRef, AfterViewInit } from '@angular/core';


declare const WebViewer: any;

@Component({
  selector: 'web-viewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.css']
})
export class WebViewerComponent implements OnInit, AfterViewInit {
  // Syntax if using Angular 8+
  // true or false depending on code
  //@ViewChild('viewer', {static: true / false}) viewer: ElementRef;

  // Syntax if using Angular 7 and below
  @ViewChild('viewer') viewer: ElementRef;

  wvInstance: any;
  
  getName()
  {
    alert("Hello! I am an alert box!!");
  }
  
  ngOnInit() {
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    
  }

  wvDocumentLoadedHandler(): void {
    // you can access docViewer object for low-level APIs
    const { documentViewer, annotationManager, Annotations } = this.wvInstance.Core;
    // and access classes defined in the WebViewer iframe
  
  }


  ngAfterViewInit(): void {
    // The following code initiates a new instance of WebViewer.

    WebViewer({
        path: '../../wv-resources/lib',
        //initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf',
        showLocalFilePicker: true,
        fullAPI: true,
        licenseKey: 'demo:1637494182302:7896051b03000000005f163f01e4647bca944fa0c872ac65f18c4752f1',
     
        disabledElements: [
          'signatureModal'
        ]
       }, 
      
        this.viewer.nativeElement)
    .then(instance => {
      instance.UI.loadDocument('https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf', { filename: 'webviewer-demo.pdf' });
  
      const { documentViewer, annotationManager  } = instance.Core;

      const style = instance.UI.iframeWindow.document.documentElement.style;

      style.setProperty(`--primary-button`, 'red');



      documentViewer.addEventListener('documentLoaded', async () => {
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        const options = { xfdfString };
        const data = await doc.getFileData(options);
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });



        var FileSaver = require('file-saver');
    
     //   FileSaver.saveAs(blob, "src/hello1.pdf");

      //  alert("Hello! I am an alert box!!");

      });

      instance.UI.disableElements(['ribbons']);
      instance.UI.setToolbarGroup('toolbarGroup-FillAndSign');


      instance.UI.setHeaderItems(header => {

        header.getHeader('toolbarGroup-FillAndSign')

        const items = header.getItems().slice(5, -8,

          {
            type: 'toolGroupButton',
            toolGroup: 'cropTools',
            dataElement: 'lineToolGroupButton',
            title: 'Crop Area',
          }
          );
        

        

        header.update(items);
        header.unshift(items);
      
      });
      



    });
   
  }




}

function getName() {
  throw new Error('Function not implemented.');
}
