import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MenuController } from "@ionic/angular";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";



@Injectable({
  providedIn: "root",
})

export class GenericCRUDService {


  //adding this in temporarily:
  loaded: boolean;
  TicketFilter: string;
  filter:any={};
  nonCompliantAuditItems: any[] = [];
  showMenu: boolean;
  GenPopP:string;

  //Global
  projref:string;

  allowedMenuItems: any[] = [];
  public appPages = [

    { title: 'Company Dashboard', url: '/company-overview', icon: 'business', permission: 'Admin' },
    {
      title: 'External Collaborators',
      url: '/company-dashboard',
      icon: 'people',
      permission: 'Admin',
      
    },
    {
      title: 'Facilities Management',
      url: '/facilities-management',
      icon: 'earth',
      permission: 'General',
    }
    
    ,
    {
      title: 'Projects',
      url: '/projects',
      icon: 'hammer',
      permission: 'General',
    },
    {
      title: 'Documents',
      url: '/project-select/1',
      icon: 'documents',
      permission: 'General',
    },
    {
      title: 'Audits/Inspection',
      url: '/project-select/0',
      icon: 'clipboard',
      permission: 'General',
    },


    {
      title: 'Incident',
      url: '/project-select/2',
      icon: 'fitness',
      permission: 'General',
    },

    {
      title: 'RFI',
      url: '/project-select/3',
      icon: 'book',
      permission: 'General',
    },
    {
      title: 'Snags',
      url: '/project-select/4',
      icon: 'receipt',
      permission: 'General',
    },
    {
      title: 'Submittals',
      url: '/project-select/5',
      icon: 'git-compare',
      permission: 'General',
    },
    {
      title: 'Tasks',
      url: '/project-select/6',
      icon: 'calendar-number',
      permission: 'General',
    },
    {
      title: 'Archive 360',
      url: '/project-select/7',
      icon: 'Camera',
      permission: 'General',
    },


    
   // { title: 'Register', url: 'register', icon: 'person-add' },
  //  { title: 'Login', url: '', icon: 'log-in' }
  ];

  constructor(private db: AngularFirestore, public menu: MenuController) {}

  toggleMenu(){
    this.menu.toggle();
  }

  get(collection: string, id: string){

    return this.db.doc(`${collection}/${id}`).get();

  }
  getdoc(path: string){

    return this.db.doc(`${path}`).get();
  }

  getAllProjects<T>(compref:string): Observable<T[]> {
    var collection = "Companies/"+compref+'/Projects';
   
      return this.db.collection(`${collection}`).get()
      .pipe(map(result => this.createArray<T>(result)));
    
  }

  getAllItems<T>(): Observable<T[]> {
    var collection = "items";
   
      return this.db.collection(`${collection}`).get()
      .pipe(map(result => this.createArray<T>(result)));
    
  }

  getAllDesc<T>(collection: string, orderBy?: string,): Observable<T[]> {
   
      return this.db.collection(`${collection}`,x=>x.orderBy(orderBy, "desc"))
      .get()
      .pipe(map(result => this.createArray<T>(result)));
 
  }


  getAll<T>(collection: string, orderBy?: string,): Observable<T[]> {
    if(orderBy){
      return this.db.collection(`${collection}`, x => x.orderBy(orderBy)).get()
      .pipe(map(result => this.createArray<T>(result)));
    }
    else{
      return this.db.collection(`${collection}`).get()
      .pipe(map(result => this.createArray<T>(result)));
    }
  }

  getAllWhere<T>(collection: string, condition1: any, operator: any, condition2: any,  orderBy?: string): Observable<T[]> {
    if(orderBy){
     
      return this.db.collection(`${collection}`, x => x.where(condition1, operator, condition2).orderBy(orderBy)).get()

      .pipe(map(result => this.createArray<T>(result)));

    }
    else{
      
      return this.db.collection(`${collection}`, x => x.where(condition1, operator, condition2)).get()

      .pipe(map(result => this.createArray<T>(result)));
    }
  }

  getAllWhereDesc<T>(collection: string, condition1: any, operator: any, condition2: any,  orderBy?: string): Observable<T[]> {
    if(orderBy){
      return this.db.collection(`${collection}`, x => x.where(condition1, operator, condition2).orderBy(orderBy,'desc')).get()
      .pipe(map(result => this.createArray<T>(result)));
    }
    else{
      return this.db.collection(`${collection}`, x => x.where(condition1, operator, condition2)).get()
      .pipe(map(result => this.createArray<T>(result)));
    }
  }

  create<T>(collection: string, object: Partial<T>):Observable<any>{
      return from(this.db.collection(`${collection}`).add(object));
  }

  update<T>(collection: string, id: string, changes: Partial<T>): Observable<any>{


    return from(this.db.doc(`${collection}/${id}`).update(changes));
    
  }
  updatedoc<T>(collection: string, changes: Partial<T>): Observable<any>{
    return from(this.db.doc(`${collection}`).update(changes));
  }

  delete(collection: string, id: string){
    return from(this.db.doc(`${collection}/${id}`).delete());
  }
  deletedoc(collection: string){
    
    return from(this.db.doc(`${collection}`).delete());
  }

  createArray<T>(results) {
    return <T[]>results.docs.map((snap) => {
      return {
        id: snap.id,
        path: snap.ref.path,
        ...<any>snap.data()
      }
    });
  }

getUserCompanyRef():string{
 return  JSON.parse(localStorage.getItem('UserDeets')).CompanyRef;
}

getUserCompanyName():string{
  return  JSON.parse(localStorage.getItem('UserDeets')).CompanyName;
 }

// getProjects():string{
//   return  JSON.parse(localStorage.getItem('UserDeets')).CompanyRef;
//  }



 getProjectDetails():string{
  return  JSON.parse(localStorage.getItem('ProjectDetails'));
 }

 getProjectRef():string{
  return  JSON.parse(localStorage.getItem('UserDeets')).ProjectRef;
 }

 getProjectRef1():string{
  
  return  JSON.parse(localStorage.getItem('ProjectRef1'));
 }

 getUserEmail():string{
  return  JSON.parse(localStorage.getItem('UserDeets')).UserEmail;
 }

 getUser():string{
  return  JSON.parse(localStorage.getItem('UserDeets'));
 }

 getUserName():string{
  var firstname = JSON.parse(localStorage.getItem('UserDeets')).FirstName;
  var lastname = JSON.parse(localStorage.getItem('UserDeets')).Lastname;
  var name = firstname + " " + lastname;
  return  name;
 }


getPermission():string{
  return  JSON.parse(localStorage.getItem('UserDeets')).Permission;
}

getDrawinglink():string{

  
  return  JSON.parse(localStorage.getItem('DrawingLink'));
}

getTransDate():string{
  return  JSON.parse(localStorage.getItem('TransDate'));
}

getTransEmail():string{
  return  JSON.parse(localStorage.getItem('email'));
}

getTransName():string{
  return  JSON.parse(localStorage.getItem('fullname'));
}

getVerRelease():string{
  return  JSON.parse(localStorage.getItem('VerRelease'));
}




GetGeneralPermissions(){
 var permission:string;
  permission = JSON.parse(localStorage.getItem('UserDeets')).Permission;
  if(permission == "Admin"){
    permission = "Admin";
        }else{
          permission= "General";
        }
        return permission;
}




displayMenuItems() {
  if(localStorage.getItem('UserDeets')){
    this.allowedMenuItems = [];
    const user: any = JSON.parse(localStorage.getItem('UserDeets'));
    if(user.Permission === 'Admin'){
      this.allowedMenuItems = this.appPages;
      this.showMenu = true;
    }
    
    else{ //general user
      this.appPages.forEach(link => {
        if(link.permission === 'General'){
          this.allowedMenuItems.push(link);
          this.showMenu = true;
        }
      });
    }
  }
}
}

