import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TriggerAnnotated } from 'firebase-functions/v1';
import { GenericCRUDService } from 'src/app/services/gencrud.service';
import { AccountService } from 'src/app/services/account.service';
import { debug } from 'console';
import { DocstorageService } from 'src/app/services/docstorage.service';
import { DatePipe } from '@angular/common';
import { LowerCasePipe } from '@angular/common';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { ModalController } from '@ionic/angular';
import Swal from 'sweetalert2/dist/sweetalert2.js';



@Component({
    selector: 'upload-task',
    templateUrl: './upload-task.component.html',
    styleUrls: ['./upload-task.component.css'],
    providers:[DatePipe, LowerCasePipe],

})
export class UploadTaskComponent implements OnInit {
    

    @Input() file: File;
    @Input() ProjectRef:string;
    @Input() FileConvention:any;
    @Input() EnableFile:boolean;

    @Input() CompanyName:string;
    @Input() Foldername:string;
    @Input() DocPathForFile:string;
    @Input() DocPath:string;
    @Input() IndexSum:number;

    @Input() fullname:string;
    @Input() email:string;

    @Input() archive360:boolean;
    @Input() AreaRef:string;

    Folders:any[]=[];
    
    task: AngularFireUploadTask;                                        // this does the uploading for us
    tryy:any = {};  
    Folder:boolean;
    percentage: Observable<number>;
    snapshot: Observable<any>;
    downloadURL: string;
    asb: string;
    Strepie: string;
    path1: string;
    moddy:any = {};
    sum:number = 0;
    data: any = {};
    TransDate =Date;
    CompanyRef :any;
    model:any ={};

    modelAuto: any = {};
    auto:boolean;
    disauto:boolean=false;
    moddy2:any = [];
    moddy3:any = [];

    NewPath:any;
    RoleName:any[];
    RoleRule:any[];
    NormDate:string;
    
    path: string;
    path360: string;


    constructor(
      
      private modalController: ModalController,
      private lowerPipe:LowerCasePipe,
      private datePipe:DatePipe,
      private storage: AngularFireStorage,
      private docService:DocstorageService,
      private accountService:AccountService, 
      private db: AngularFirestore,
      private genService:GenericCRUDService
      
      ) 
      
      {  }

    ngOnInit(): void {

        this.CompanyRef= this.genService.getUserCompanyRef()
        this.tryy = null 
        this.getAutoValue();  

        console.log ('indexsum ' + this.IndexSum)
    }

    //Get File Convention values fo ISO standards in evaluating the name.


Checkdoc(){
//Change last file to version True

if(this.DocPath === undefined){



  if ( this.disauto == true &&  this.modelAuto.Path !== undefined){

    this.path1  = this.modelAuto.Path 

  }


  else {
    
        this.path1 = 'Companies/' +this.CompanyRef+'/Projects/'+this.ProjectRef+ '/Documents';

      }
      
      //  this.docService.getFolder(this.ProjectRef).subscribe((res) => {
        this.genService
          .getAllWhere(this.path1, 'Name', '==', this.file.name)
          .subscribe((res) => {

            
        var data:any[]=[];
        data = res;
        data.forEach(element => {
          
        this.sum = this.sum + 1
        

        var moddy:any = {};
        moddy = element;
        this.model = moddy;

        if (moddy.Version === false){

  if (this.disauto == true &&  this.modelAuto.Path !== undefined){

    this.path1  = this.modelAuto.Path

  }


  else {
    
        this.path1 = 'Companies/' +this.CompanyRef+'/Projects/'+this.ProjectRef+ '/Documents';

      }
            var mod:any ={};
            mod.Version = true;
           
            this.genService.update(this.path1,moddy.id,mod).subscribe(res =>{
          
            },(err)=>{
          
            },()=>{
          
         
                   });
          }
          });

    
      })

}

else

{


    ////////////////Folder Versioning search
    console.log (this.DocPathForFile)


    // this.path1 = 'Companies/' +this.CompanyRef+'/Projects/'+this.ProjectRef+ '/Documents';

    if (this.disauto == true &&  this.modelAuto.Path !== undefined){    this.path1  = this.modelAuto.Path
  }

    else 
    {


    this.path1 = this.DocPathForFile
    }

      //  this.docService.getFolder(this.ProjectRef).subscribe((res) => {

        
        this.genService
          .getAllWhere(this.path1, 'Name', '==', this.file.name)
          .subscribe((res) => {
            

        var data:any[]=[];
        data = res;
        data.forEach(element => {
              
        this.sum = this.sum + 1

        var moddy:any = {};
        moddy = element;
        
        if (moddy.Version === false){
          
            var mod:any ={};
            mod.Version = true;

            this.genService.update(this.path1,moddy.id,mod).subscribe(res =>{
              
            },(err)=>{
          
            },()=>{
          
         
                   });
          }
          });  
      })
}

}

     
FileWrongAlert(){
    
    Swal.fire({
      text: 'File name not valid: ' + this.file.name,
      icon: 'warning',
      confirmButtonColor: "#5195FF",   


    });
  }
/////////////////GET FOLDERS AND FILES FROM FIREBASE

getAutoValue(){
  
  debugger
//Archive360 Upload Management
  if (this.archive360 === true)
  {
    this.startUpload360()
  }

  else {
//Document Management Upload Process
  if (this.EnableFile === true)
  {
   
  

  this.tryy = undefined;
  this.FileConvention = this.FileConvention + '+(.dwg |.pdf)$'

 let oops =  new RegExp(this.FileConvention);
 this.tryy =  this.file.name.match(oops);

  }
  
  
  
  if (this.tryy === null || Number.isNaN(this.IndexSum)) 
  
  {

  this.startUpload();
  
  }

else

{
    // let splitpath = this.DocPathForFile.slice(0, -10);
    // this.genService.getAllWhereDesc(this.NewPath,'Foldername', '==', this.Foldername).subscribe(res =>{   

      var path =
  
      'Companies/' + this.CompanyRef + '/RoleNames/'
     debugger
      let codestring= this.IndexSum.toString()
    this.genService.getAllWhereDesc(path,'Code', '==', this.tryy[this.IndexSum]).subscribe(res =>{        
      // this.genService.getAllWhere(path,'Code', '==', codestring).subscribe(res =>{        

    this.RoleName = res;
    
    
    this.RoleName.forEach((item) => {
      
      this.modelAuto.Name = item.Name
   
      });
  
  
    this.moddy2 =  this.RoleName 
    
    // Add automation rule only when your in a folder
      if (this.moddy2.length == 0 )
      { 
          this.disauto = false
          this.startUpload();

      } 
  
      else
  
      {

        
        var path2 =
  
        'Companies/' + this.CompanyRef + '/RoleRules/'
        
        this.genService.getAllWhere(path2,'RoleName', '==', this.modelAuto.Name).subscribe(res2 =>{      
          
          this.RoleRule = res2;

          this.RoleRule.forEach((item) => {
            
            this.modelAuto.Path = item.folderPath
            
            });      
             
            this.moddy3 =  this.RoleRule 

            

            if (this.moddy3.length !== 0)
            { 
                this.disauto = true
            } 
        
            
            this.startUpload();


          })
      }
  
    
    }
    );
  
  }

}}

startUpload360() {

  
  console.log('uploading file', this.file);

  let safeName = this.file.name.replace(/([^a-z0-9.]+)/gi, '');   // file name stripped of spaces and special chars
  let timestamp = Date.now();                                     // ex: '1598066351161'
  const uniqueSafeName = timestamp + '_' + safeName;


  debugger

if (this.AreaRef === undefined)

{
  // const path = 'uploads/' + uniqueSafeName;                       // Firebase storage path
  this.path360 = '/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/360floorplans/'  + uniqueSafeName
  this.path1 = '/Companies/' +this.CompanyRef+'/Projects/'+this.ProjectRef+ '/Floorplan360/' ///////////change

}

else
{
 // const path = 'uploads/' + uniqueSafeName;                       // Firebase storage path
  this.path360 = '/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/Archive360/'  + uniqueSafeName
  this.path1 = '/Companies/' +this.CompanyRef+'/Projects/'+this.ProjectRef+ '/Area360/' + this.AreaRef + '/Images/'////////////change

}

  const ref = this.storage.ref(this.path360);                             // reference to storage bucket

  this.task = this.storage.upload(this.path360, this.file);
  this.percentage = this.task.percentageChanges();    
  
  var date = new Date();

  this.NormDate= this.datePipe.transform(date,'dd/MM/yyyy');
    
  this.model.SubmittedDate  =this.NormDate;
  
  // progress monitoring
  this.snapshot = this.task.snapshotChanges().pipe(               // emits a snapshot of the transfer progress every few hundred milliseconds
      tap(console.log),
      
      finalize(async () => {                                      // after the observable completes, get the file's download URL
          this.downloadURL = await ref.getDownloadURL().toPromise();
          
          this.db.collection(this.path1).doc(uniqueSafeName).set({
            
          // this.db.collection('files').doc(uniqueSafeName).set({
              storagePath: this.path360,
              downloadURL: this.downloadURL,
              originalName: this.file.name,
              timestamp: timestamp,
              SubmittedDate: this.NormDate
          })
              .then(function () {
                  console.log('document written!');

              })
              .catch(function (error) {
                  console.error('Error writing document:', error);
              });
              this.closeModal();

      }),
  );


}

    startUpload() {

      this.Checkdoc();   

        var checkExt = this.file.name.match(/[.]/);
        
        

        //Drag and Drop Folders in Root Directory
    if (this.DocPath === undefined && checkExt === null )
    {
        let timestamp = Date.now();                                     // ex: '1598066351161'
        // let safeName = this.file.name.replace(/([^a-z0-9.]+)/gi, '');   // file name stripped of spaces and special chars
        let safeName = this.file.name.replace(/[/\\?%*:|"<>]/g, '-');   // file name stripped of spaces and special chars

                const path1 = this.DocPath +  '/Documents' 
                this.model.Folder = true;
                this.model.Foldername = safeName
                this.docService.createFolderLevel1(this.model,this.ProjectRef).subscribe(res =>{
                   this.closeModal();
                  })

        console.log('folder')
    }

    else if (this.DocPath !== undefined && checkExt === null)
    {
        let timestamp = Date.now();                                     // ex: '1598066351161'
        // let safeName = this.file.name.replace(/([^a-z0-9.]+)/gi, '');   // file name stripped of spaces and special chars
        let safeName = this.file.name.replace(/[/\\?%*:|"<>]/g, '-');   // file name stripped of spaces and special chars

                const path1 = this.DocPath 
                this.model.Folder = true;
                this.model.Foldername = safeName
                this.docService.createFolderInFolder(this.model,path1).subscribe(res =>{
                  this.closeModal();
                

                  })

        console.log('folder')
    }

    else {    


        if (this.tryy === null && this.EnableFile === true)  {
        
        //   this.asb = 'File name not valid: ' + this.file.name ;
        
          this.FileWrongAlert();
        } 
       else
       {


        if (this.DocPath !== undefined) {
     
        //Go search if there is a rule on the code  
        // console.log ('engineering code' + this.tryy[this.IndexSum])
        


        var date = new Date();

        let safeName = this.file.name.replace(/([^a-z0-9.]+)/gi, '');   // file name stripped of spaces and special chars
        let timestamp = Date.now();                                     // ex: '1598066351161'
        const uniqueSafeName = timestamp + '_' + safeName;
        // const path = 'uploads/' + uniqueSafeName;                       // Firebase storage path
         

        // const path = '/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/Documents/'  + uniqueSafeName

        
        if (this.disauto == true &&  this.modelAuto.Path !== undefined){

          this.path  = this.modelAuto.Path +  '/'  + uniqueSafeName
          this.path1  = this.modelAuto.Path 

        }

        else
        {
          this.path  = this.DocPath +  '/Documents/'  + uniqueSafeName
          this.path1 = this.DocPath +  '/Documents' 

        }
        
        console.log ('path ' + this.DocPath +  '/Documents/'  + uniqueSafeName)

        
        
        const ref = this.storage.ref(this.path);                             // reference to storage bucket

        this.task = this.storage.upload(this.path, this.file);
        this.percentage = this.task.percentageChanges();                // progress monitoring
        this.snapshot = this.task.snapshotChanges().pipe(               // emits a snapshot of the transfer progress every few hundred milliseconds
            tap(console.log),

            
            finalize(async () => {                                      // after the observable completes, get the file's download URL
                this.downloadURL = await ref.getDownloadURL().toPromise();
                
                // this.db.collection('/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/Documents/').doc(uniqueSafeName).set({
                  
                this.db.collection(this.path1).doc(uniqueSafeName).set({
                    storagePath: this.path,
                    downloadURL: this.downloadURL,
                    Name: this.file.name,
                    timestamp: timestamp,
                    Folder:false,
                    Version:false,
                    fullname: this.fullname,
                    email:this.email,
                    TransDate: this.datePipe.transform( date,'dd/MM/yyyy'),
                    VerRelease: this.sum

                    
                })

                
                    .then(function () {
                        console.log('document written!');
                    })
                    .catch(function (error) {
                        console.error('Error writing document:', error);
                    });

                    this.closeModal();

            }
            
            
            ),
        );

    }

    else if (this.DocPath === undefined)

    {

      
        var date = new Date();

        let safeName = this.file.name.replace(/([^a-z0-9.]+)/gi, '');   // file name stripped of spaces and special chars
        let timestamp = Date.now();                                     // ex: '1598066351161'
        const uniqueSafeName = timestamp + '_' + safeName;
        // const path = 'uploads/' + uniqueSafeName;                       // Firebase storage path


        
               if (this.disauto == true &&  this.modelAuto.Path !== undefined){

                this.path  = this.modelAuto.Path +  '/'  + uniqueSafeName
                this.path1  = this.modelAuto.Path 

        }

        else
          {
            
          this.path  = '/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/Documents/'  + uniqueSafeName
          this.path1  = '/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef  + '/Documents'

          }

        // this.path = '/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/Documents/'  + uniqueSafeName

        const ref = this.storage.ref(this.path);                             // reference to storage bucket

        this.task = this.storage.upload(this.path, this.file);
        this.percentage = this.task.percentageChanges();                // progress monitoring
        this.snapshot = this.task.snapshotChanges().pipe(               // emits a snapshot of the transfer progress every few hundred milliseconds
            tap(console.log),

            
            finalize(async () => {                                      // after the observable completes, get the file's download URL
                this.downloadURL = await ref.getDownloadURL().toPromise();

                // this.db.collection('/Companies/' + this.CompanyRef +  '/Projects/' + this.ProjectRef + '/Documents/').doc(uniqueSafeName).set({
                  this.db.collection(this.path1).doc(uniqueSafeName).set({

               
                    storagePath: this.path,
                    downloadURL: this.downloadURL,
                    Name: this.file.name,
                    timestamp: timestamp,
                    Folder:false,
                    Version:false,
                    fullname: this.fullname,
                    email:this.email,
                    TransDate: this.datePipe.transform( date,'dd/MM/yyyy'),
                    VerRelease: this.sum

                    
                })
                    .then(function () {
                        console.log('document written!');
                    })
                    .catch(function (error) {
                        console.error('Error writing document:', error);
                    });

                    this.closeModal();

            }
            
            
            ),
        );

    }}

    
}
}

    isActive(snapshot) {
        return (snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes);
    }

    closeModal() {
        this.modalController.dismiss({
          dataModel: this.model
          
        });
      }

      
    

}
