import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/compat/firestore';


import { from, Observable, Subject } from 'rxjs';

import { DocumentFB } from '../models/documentFB';
import { Folder } from '../models/folder';

import {  ref, getStorage,uploadBytes, getDownloadURL , uploadString,deleteObject } from '@firebase/storage';

import { GenericCRUDService } from "./gencrud.service";
import { AccountService } from "./account.service";
import { map } from "rxjs/operators";
import { ParseError } from "@angular/compiler";


@Injectable({
  providedIn: 'root',
  
  
})
 export class DocstorageService {

    //Doc Component stuff---------------
    companyusers: any = [];
    companies: any = [];
    Folders: any = [];
    Folders1: any = [];
    ProjectUsers: any = [];
    ProjectCompanies: any = [];
    ProjectCompanyUsers: any = [];
    DocId:any;
    FileDetails: DocumentFB ;
    FolderDetailsSubject = new Subject<string>();
    DocumentUserArray:DocumentFB = new DocumentFB();
    Version:any;
    User:Array<any> = new Array<string>();
    docfile:DocumentFB[];
    IncidentFiles:any[];
    TaskFiles:any[];
    RFIFiles:any[];
    RFIResponseFiles:any[];
    SnagFiles:any[];
    SnagResponseFiles:any[];
    viewdoc:any={};
    //----------------------------------
  constructor(private db: AngularFirestore,private accountService:AccountService,private genService:GenericCRUDService) {
    
  }
  CompanyRef= this.genService.getUserCompanyRef();
  UserEmail = this.genService.getUserEmail();

      //-------------------------------------------------------------------Tickets response--------------------------------------------------------------------
    
      async UploadFileToStorageTicket(file: any, Ref: string) {



        const file1 = {
          Name: file.name,
          UserId: this.UserEmail,
          TicketRef:Ref
        };
    
        let url: string;
        const foldname = Ref + '/';
        const storage = getStorage();
        const storageRef = ref(storage, this.CompanyRef+'/TicketResponseDocs/' + foldname + file.name);
    
        let snapshot1: any;
    
        await uploadBytes(storageRef, file).then((snapshot) => {
          snapshot1 = snapshot;
    
        });
    
        //Showing you somthing
    
        const snapshot = await uploadBytes(storageRef, file);
    
    
    
        await this.getDocDownloadURLTickets(file1,  snapshot1, Ref);
    
      }
    
    
    
      getDocDownloadURLTickets= async (file: any,  snap: any, Ref: string) => {
        await getDownloadURL(snap.ref).then((downloadURL) => {
    
          const url = downloadURL;
          file.Location = url;
    
        });
        this.createdocTickets(file, Ref);
      };
    
      async createdocTickets(file: any, Ref: string) {
    
        //  var rfiref = "nWHqciiMbaV2JAGleLhf";
        var file11:any = {};
        file11.Name = file.Name;
        file11.Location = file.Location;
        
        // file11.Folder = file.Folder;
        file11.UploadTime = new Date()
        var UserId = [
          UserId= this.UserEmail
        ];
     file11.UserRef= this.UserEmail;
    
    
    
    
    var doc:any={};
    var path = 'Companies/' + this.CompanyRef + '/Tickets/' + Ref + '/TicketDocs';
        this.genService.create(path,file11).subscribe(res =>{
    
    
        });
    
    
    
    
        
    
    
      }


    //-------------------------------------------------------------------Tickets--------------------------------------------------------------------
    
    async UploadFileToStorageTicketResponse(file: any, Ref: string,newref:string) {



      const file1 = {
        Name: file.name,
        UserId: this.UserEmail,
        TicketRef:Ref
      };
  
      let url: string;
      const foldname = Ref + '/';
      const storage = getStorage();
      const storageRef = ref(storage, this.CompanyRef+'/TicketDocs/' + foldname + file.name);
  
      let snapshot1: any;
  
      await uploadBytes(storageRef, file).then((snapshot) => {
        snapshot1 = snapshot;
  
      });
  
      //Showing you somthing
  
      const snapshot = await uploadBytes(storageRef, file);
  
  
  
      await this.getDocDownloadURLTicketResponse(file1,  snapshot1, Ref,newref);
  
    }
  
  
  
    getDocDownloadURLTicketResponse= async (file: any,  snap: any, Ref: string,newref:string) => {
      await getDownloadURL(snap.ref).then((downloadURL) => {
  
        const url = downloadURL;
        file.Location = url;
  
      });
      this.createdocTicketResponse(file, Ref,newref);
    };
  
    async createdocTicketResponse(file: any, Ref: string,newref:string) {
  
      //  var rfiref = "nWHqciiMbaV2JAGleLhf";
      var file11:any = {};
      file11.Name = file.Name;
      file11.Location = file.Location;
      
      // file11.Folder = file.Folder;
      file11.UploadTime = new Date()
      var UserId = [
        UserId= this.UserEmail
      ];
   file11.UserRef= this.UserEmail;
  
  
  
  
  var doc:any={};
  var path = 'Companies/' + this.CompanyRef + '/Tickets/' + Ref + '/SubmittedResponse/'+newref+'/TicketResponseDocs';
      this.genService.create(path,file11).subscribe(res =>{
  
  
      });
  
  
  
  
      
  
  
    }

  
    //-------------------------------------------------------------------Audits proof--------------------------------------------------------------------
    async uploadImage(file: any, Ref: string) {
      


        let url: string;
          
        const storage = getStorage();
        const storageRef = ref(storage, this.CompanyRef+'/ProofDocs/'  + file.name);
      
        let snapshot1: any;
      
      
        
        await uploadString(storageRef, file.Base64, 'data_url').then((snapshot) => {
          snapshot1 = snapshot;
      
        });
      
      
        //Showing you somthing
      
       // const snapshot = await uploadBytes(storageRef, file);
      
      
      
        
      await getDownloadURL(snapshot1.ref).then((downloadURL) => {
      
      const url = downloadURL;
      
      file.Location = url
      
      });
      

      return file;
  }

  //-------------------------------------------------------------------RFI----------------------------------------------------------------------------
  async UploadFileToStorageProof(file: any, Ref: string) {




    
  const storage = getStorage();
  const storageRef = ref(storage, this.CompanyRef+'/ProofDocs/'  + file.name);

  let snapshot1: any;


  
  await uploadBytes(storageRef, file).then((snapshot) => {
    snapshot1 = snapshot;

  });

 await getDownloadURL(snapshot1.ref).then((downloadURL) => {

  const url = downloadURL;
  
  file.Location = url

});

return file;
  }



  getDocDownloadURLProof = async (file: any, snap: any, Ref: string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
     return file.Location = url;

    });
   // this.createdocProof(file, Ref);
  };

//   async createdocProof(file: any, Ref: string) {

//     //  var rfiref = "nWHqciiMbaV2JAGleLhf";
//     var file11:any = {};
//     file11.Name = file.Name;
//     file11.Location = file.Location;
    
//     //file11.Folder = file.Folder;
//     file11.UploadTime = new Date()
//     var UserId = [
//       UserId= this.UserEmail
//     ];
//  file11.UserRef= this.UserEmail;




// var doc:any={};
//     var path = 'Companies/' + this.CompanyRef + '/Rfis/' + Ref + '/RfiDocs';
//     this.genService.create(path,file11).subscribe(res =>{


//     });




    


//   }


//     async UploadFileToStorageAuditsproof(file: any) {

//       const file1 = {
//         Name: file.name,
//         Location: "",
        
//       };
  
//       let url: string;
//       const foldname = this.CompanyRef + '/';
//       const storage = getStorage();
//       const storageRef = ref(storage, this.CompanyRef+'/AuditProof/'  + file.file.name);
  
//       let snapshot1: any;
//   
//       await uploadBytes(storageRef, file).then((snapshot) => {
//         snapshot1 = snapshot;
  
//       });
// // 
// //       const file1 = {
// //         Name: file.file.name,
// //         Location:"",
// //       };
  
// //       let url: string;
     
// //       const storage = getStorage();
// //       const storageRef = ref(storage, this.CompanyRef+'/AuditProof/'  + file.FileName);
  
// //       let snapshot1: any;
      

  
//       //Showing you somthing
  
//       const snapshot = await uploadBytes(storageRef, file);
  
//       await getDownloadURL(snapshot1.ref).then((downloadURL) => {
        
//         const url = downloadURL;
//         file1.Location = url;
  
//       });
  
//      return file1;
//      // await this.getDocDownloadURLAuditsproof(file1,  snapshot1, Ref,newref);
  
//     }
  
  
  
//     getDocDownloadURLAuditsproof= async (file: any,  snap: any, Ref: string,newref:string) => {
//       await getDownloadURL(snap.ref).then((downloadURL) => {
  
//         const url = downloadURL;
//         file.Location = url;
  
//       });
//       this.createdocAuditsproof(file, Ref,newref);
//     };
  
//     async createdocAuditsproof(file: any, Ref: string,newref:string) {
  
//       //  var rfiref = "nWHqciiMbaV2JAGleLhf";
//       var file11:any = {};
//       file11.Name = file.Name;
//       file11.Location = file.Location;
      
//       // file11.Folder = file.Folder;
//       file11.UploadTime = new Date()
//       var UserId = [
//         UserId= this.UserEmail
//       ];
//    file11.UserRef= this.UserEmail;
  
  
  
  
//   var doc:any={};
//   var path = 'Companies/' + this.CompanyRef + '/Audits/' + Ref + '/SubmittedItems/'+newref+'/docs';
//       this.genService.create(path,file11).subscribe(res =>{
  
  
//       });
  
  
  
  
      
  
  
//     }
    //-------------------------------------------------------------------Audits--------------------------------------------------------------------
    
  async UploadFileToStorageAudits(file: any, Ref: string,newref:string) {



    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      SubmittalRef:Ref
    };

    let url: string;
    const foldname = Ref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/AuditDocs/' + foldname + file.name);

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURLAudits(file1,  snapshot1, Ref,newref);

  }



  getDocDownloadURLAudits= async (file: any,  snap: any, Ref: string,newref:string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdocAudits(file, Ref,newref);
  };

  async createdocAudits(file: any, Ref: string,newref:string) {

    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.Name = file.Name;
    file11.Location = file.Location;
    
    // file11.Folder = file.Folder;
    file11.UploadTime = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
 file11.UserRef= this.UserEmail;




var doc:any={};
var path = 'Companies/' + this.CompanyRef + '/Audits/' + Ref + '/SubmittedItems/'+newref+'/docs';
    this.genService.create(path,file11).subscribe(res =>{


    });




    


  }
  
  //-------------------------------------------------------------------Submittals--------------------------------------------------------------------

  async UploadFileToStorageSubmittals(file: any, Ref: string) {
    


    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      SubmittalRef:Ref
    };

    let url: string;
    const foldname = Ref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/SubmittalsDocs/' + foldname + file.name);

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURLSubmittals(file1,  snapshot1, Ref);

  }



  getDocDownloadURLSubmittals= async (file: any,  snap: any, Ref: string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdocSubmittals(file, Ref);
  };

  async createdocSubmittals(file: any, Ref: string) {
    
    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.Name = file.Name;
    file11.Location = file.Location;
    
    // file11.Folder = file.Folder;
    file11.UploadTime = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
 file11.UserRef= this.UserEmail;




var doc:any={};
var path = 'Companies/' + this.CompanyRef + '/Submittals/' + Ref + '/SubmittalDocs';
    this.genService.create(path,file11).subscribe(res =>{


    });




    


  }

  
  //-------------------------------------------------------------------Submittals Reply--------------------------------------------------------------------

  async UploadFileToStorageSubmittalReply(file: any, Ref: string,subref:string) {



    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      SubmittalRef:Ref
    };

    let url: string;
    const foldname = Ref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/SubmittalsDocs/' + foldname + file.name);

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURLSubmittalReply(file1,  snapshot1, Ref,subref);

  }



  getDocDownloadURLSubmittalReply= async (file: any,  snap: any, Ref: string,subref:string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdocSubmittalReply(file, Ref,subref);
  };

  async createdocSubmittalReply(file: any, Ref: string,subref:string) {

    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.Name = file.Name;
    file11.Location = file.Location;
    
    // file11.Folder = file.Folder;
    file11.UploadTime = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
 file11.UserRef= this.UserEmail;




var doc:any={};
var path = 'Companies/' + this.CompanyRef + '/Submittals/' + Ref +'/Responses/'+subref + '/docs';
    this.genService.create(path,file11).subscribe(res =>{


    });




    


  }

    //-------------------------------------------------------------------Submittals forwarded--------------------------------------------------------------------

    async UploadFileToStorageSubmittalFor(file: any, Ref: string,subref:string) {



      const file1 = {
        Name: file.name,
        UserId: this.UserEmail,
        SubmittalRef:Ref
      };
  
      let url: string;
      const foldname = Ref + '/';
      const storage = getStorage();
      const storageRef = ref(storage, 'RDS/SubmittalsDocs/' + foldname + file.name);
  
      let snapshot1: any;
  
      await uploadBytes(storageRef, file).then((snapshot) => {
        snapshot1 = snapshot;
  
      });
  
      //Showing you somthing
  
      const snapshot = await uploadBytes(storageRef, file);
  
  
  
      await this.getDocDownloadURLSubmittalFor(file1,  snapshot1, Ref,subref);
  
    }
  
  
  
    getDocDownloadURLSubmittalFor= async (file: any,  snap: any, Ref: string,subref:string) => {
      await getDownloadURL(snap.ref).then((downloadURL) => {
  
        const url = downloadURL;
        file.Location = url;
  
      });
      this.createdocSubmittalFor(file, Ref,subref);
    };
  
    async createdocSubmittalFor(file: any, Ref: string,subref:string) {
  
      //  var rfiref = "nWHqciiMbaV2JAGleLhf";
      var file11:any = {};
      file11.Name = file.Name;
      file11.Location = file.Location;
      
      // file11.Folder = file.Folder;
      file11.UploadTime = new Date()
      var UserId = [
        UserId= this.UserEmail
      ];
   file11.UserRef= this.UserEmail;
  
  
  
  
  var doc:any={};
  var path = 'Companies/' + this.CompanyRef + '/Submittals/' + Ref +'/ForwardedResponses/'+subref + '/docs';
      this.genService.create(path,file11).subscribe(res =>{
  
  
      });
  
  
  
  
      
  
  
    }

        //-------------------------------------------------------------------Submittals Forwarded Response--------------------------------------------------------------------

        async UploadFileToStorageSubmittalForReply(file: any, Ref: string,subref:string,resref:string) {



          const file1 = {
            Name: file.name,
            UserId: this.UserEmail,
            SubmittalRef:Ref
          };
      
          let url: string;
          const foldname = Ref + '/';
          const storage = getStorage();
          const storageRef = ref(storage, 'RDS/SubmittalsDocs/' + foldname + file.name);
      
          let snapshot1: any;
      
          await uploadBytes(storageRef, file).then((snapshot) => {
            snapshot1 = snapshot;
      
          });
      
          //Showing you somthing
      
          const snapshot = await uploadBytes(storageRef, file);
      
      
      
          await this.getDocDownloadURLSubmittalForReply(file1,  snapshot1, Ref,subref,resref);
      
        }
      
      
      
        getDocDownloadURLSubmittalForReply= async (file: any,  snap: any, Ref: string,subref:string,resref:string) => {
          await getDownloadURL(snap.ref).then((downloadURL) => {
      
            const url = downloadURL;
            file.Location = url;
      
          });
          this.createdocSubmittalForReply(file, Ref,subref,resref);
        };
      
        async createdocSubmittalForReply(file: any, Ref: string,subref:string,resref:string) {
      
          //  var rfiref = "nWHqciiMbaV2JAGleLhf";
          var file11:any = {};
          file11.Name = file.Name;
          file11.Location = file.Location;
          
          // file11.Folder = file.Folder;
          file11.UploadTime = new Date()
          var UserId = [
            UserId= this.UserEmail
          ];
       file11.UserRef= this.UserEmail;
      
      
      
      
      var doc:any={};
      var path = 'Companies/' + this.CompanyRef + '/Submittals/' + Ref +'/ForwardedResponses/'+subref + '/Responses/' + resref + '/docs';
          this.genService.create(path,file11).subscribe(res =>{
      
      
          });
      
        }

  //-------------------------------------------------------------------Tasks--------------------------------------------------------------------------
  async UploadFileToStorageTask(file: any, Ref: string) {



    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      TaskRef:Ref
    };

    let url: string;
    const foldname = Ref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/TaskDocs/' + foldname + file.name);

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURLTask(file1, snapshot1, Ref);

  }



  getDocDownloadURLTask = async (file: any,  snap: any, Ref: string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdocTask(file, Ref);
  };

  async createdocTask(file: any, Ref: string) {

    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    const file11: any = {};
    file11.Name = file.Name;
    file11.Location = file.Location;
    file11.UploadTime = new Date();
    file11.UserRef = this.UserEmail;



var doc:any={};
    var path = 'Companies/' + this.CompanyRef + '/Tasks/' + Ref + '/TaskDocs';
    this.genService.create(path,file11).subscribe(res =>{


    });




    


  }

  // getTaskFiles = async (ref: string) => {

  //   var path = 'Companies/' + this.CompanyRef + '/Tasks/' + ref + '/TaskDocs';
  //   this.genService.getAll(path).subscribe(res => {
  //     this.TaskFiles = res;
  //   });
  //   return this.TaskFiles;
  // };
    //-------------------------------------------------------------------RFI Response----------------------------------------------------------------------------
    // getDrawing(){
    //   const storage = getStorage();
    //   const storageRef = ref(storage, 'RDS/RfiResponses/' + foldname + file.name);
    // }
    async UploadFileToStorageRFIResponse(file: any, Ref: string,responseref:string) {



        const file1 = {
          Name: file.name,
          UserId: this.UserEmail,
          RfiResponseRef: responseref
        };
    
        let url: string;
        const foldname = responseref + '/';
        const storage = getStorage();
        const storageRef = ref(storage, 'RDS/RfiResponses/' + foldname + file.name);
    
        let snapshot1: any;
    
        await uploadBytes(storageRef, file).then((snapshot) => {
          snapshot1 = snapshot;
    
        });
    
        //Showing you somthing
    
        const snapshot = await uploadBytes(storageRef, file);
    
    
    
        await this.getDocDownloadURLRFIResponse(file1, snapshot1, Ref,responseref);
    
      }
    
    
    
      getDocDownloadURLRFIResponse = async (file: any,  snap: any, Ref: string,responseref:string) => {
        await getDownloadURL(snap.ref).then((downloadURL) => {
    
          const url = downloadURL;
          file.Location = url;
    
        });
        this.createdocRFIResponse(file, Ref,responseref);
      };
    
      async createdocRFIResponse(file: any, Ref: string,responseref:string) {
    
        //  var rfiref = "nWHqciiMbaV2JAGleLhf";
        var file11:any = {};
        file11.Name = file.Name;
        file11.Location = file.Location;
        
        //file11.Folder = file.Folder;
        file11.UploadTime = new Date()
        var UserId = [
          UserId= this.UserEmail
        ];
        
        file11.UserRef= this.UserEmail;
    
    
    
    
    var doc:any={};
        var path = 'Companies/' + this.CompanyRef + '/Rfis/' + Ref + '/Responses/'+ responseref+'/Docs';
        this.genService.create(path,file11).subscribe(res =>{
    
    
        });
    
    
    
    
        
    
    
      }
    
      // getRFIResponseFiles = async (ref: string,responseref:string) => {
    
      //   var path = 'Companies/' + this.CompanyRef + '/Rfis/' + ref + '/Responses/'+responseref+'/Docs';
      //   this.genService.getAll(path).subscribe(res => {
      //     this.RFIResponseFiles = res;
      //   });
      //   return this.RFIResponseFiles;
      // };

  //-------------------------------------------------------------------RFI----------------------------------------------------------------------------
  async UploadFileToStorageRFI(file: any, Ref: string) {
    


    if (file !== undefined){
//get the data that was sent to this service and add it to an object
    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      RfiRef: Ref
  
    };

    //create storage location for firebase storage
    let url: string;
    const foldname = Ref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/RfiDocs/' + foldname + file.name);

    let snapshot1: any;
    
    //upload base64 to firebase
    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    await this.getDocDownloadURLRFI(file1,  snapshot1, Ref);

  }

  }  



  getDocDownloadURLRFI = async (file: any, snap: any, Ref: string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {
//getting the url path of uploaded documnet
      const url = downloadURL;
      file.Location = url;

    });
    this.createdocRFI(file, Ref);
  };

  async createdocRFI(file: any, Ref: string) {
//adding file details and url to firestore.


    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.Name = file.Name;
    file11.Location = file.Location;
    
    //file11.Folder = file.Folder;
    file11.UploadTime = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
 file11.UserRef= this.UserEmail;


var doc:any={};
    var path = 'Companies/' + this.CompanyRef + '/Rfis/' + Ref + '/RfiDocs';
    this.genService.create(path,file11).subscribe(res =>{

    });
  }

  // getRFIFiles = async (ref: string) => {

  //   var path = 'Companies/' + this.CompanyRef + '/Rfis/' + ref + '/RfiDocs';
  //   this.genService.getAll(path).subscribe(res => {
  //     this.RFIFiles = res;
  //   });
  //   return this.RFIFiles;
  // };
  //-------------------------------------------------------------------Snags--------------------------------------------------------------------------
  async UploadFileToStorageSnag(file: any, Ref: string) {



    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      SnagRef:Ref
    };

    let url: string;
    const foldname = Ref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/SnagDocs/' + foldname + file.name);

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURLSnag(file1,  snapshot1, Ref);

  }

  async UploadFileToStorage360(file: any, Ref: string,CompanyRef:string,ProjectRef:string, Length:number) {

    

    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      SnagRef:Ref
      
    };

    let url: string;
    const foldname = CompanyRef + '/';
    const storage = getStorage();
    // const storageRef = ref(storage,'360/' + foldname + ProjectRef + file.name);

    const storageRef = ref(storage, 'Companies/' + foldname + 'Projects/' + ProjectRef + '/360floorplans/' + file.name);
   

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURL360(file1,  snapshot1, Ref,ProjectRef,Length);

  }


  getDocDownloadURL360= async (file: any,  snap: any, Ref: string,ProjectRef:string,Length:number) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdoc360(file, Ref, ProjectRef,Length);
  };

  async createdoc360(file: any, Ref: string,ProjectRef:string,Length:number) {

    
    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.originalName = file.Name;
    file11.downloadURL = file.Location;
    file11.Length = Length;

    //file11.Folder = file.Folder;
    file11.SubmittedDate = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
    file11.UserRef= this.UserEmail;

    var doc:any={};
    var path = 'Companies/' + this.CompanyRef + '/Projects/' + ProjectRef + '/Floorplan360/';

    this.genService.create(path,file11).subscribe(res =>{


    });

  }





  ////////////////////////////////////////////////////////////
  getDocDownloadURLSnag= async (file: any,  snap: any, Ref: string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdocSnag(file, Ref);
  };

  async createdocSnag(file: any, Ref: string) {

    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.originalName = file.Name;
    file11.downloadURL = file.Location;
    
    //file11.Folder = file.Folder;
    file11.UploadTime = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
    file11.UserRef= this.UserEmail;

var doc:any={};
var path = 'Companies/' + this.CompanyRef + '/Snags/' + Ref + '/SnagDocs';
    this.genService.create(path,file11).subscribe(res =>{


    });

  }

  getSnagFiles = async (ref: string) => {

    var path = 'Companies/' + this.CompanyRef + '/Snags/' + ref + '/SnagDocs';
    this.genService.getAll(path).subscribe(res => {
      this.SnagFiles = res;
    });
    return this.SnagFiles;
  };
  //-------------------------------------------------------------------Snag Response--------------------------------------------------------------------------
  async UploadFileToStorageSnagResponse(file: any, Ref: string,responseref:string) {



    const file1 = {
      Name: file.name,
      UserId: this.UserEmail,
      SnagResponseRef:responseref
    };

    let url: string;
    const foldname = responseref + '/';
    const storage = getStorage();
    const storageRef = ref(storage, 'RDS/SnagResponses/' + foldname + file.name);

    let snapshot1: any;

    await uploadBytes(storageRef, file).then((snapshot) => {
      snapshot1 = snapshot;

    });

    //Showing you somthing

    const snapshot = await uploadBytes(storageRef, file);



    await this.getDocDownloadURLSnagResponse(file1,  snapshot1, Ref,responseref);

  }



  getDocDownloadURLSnagResponse = async (file: any,  snap: any, Ref: string,responseref:string) => {
    await getDownloadURL(snap.ref).then((downloadURL) => {

      const url = downloadURL;
      file.Location = url;

    });
    this.createdocSnagResponse(file, Ref,responseref);
  };

  async createdocSnagResponse(file: any, Ref: string,responseref:string) {

    //  var rfiref = "nWHqciiMbaV2JAGleLhf";
    var file11:any = {};
    file11.Name = file.Name;
    file11.Location = file.Location;
    
    //file11.Folder = file.Folder;
    file11.UploadTime = new Date()
    var UserId = [
      UserId= this.UserEmail
    ];
    file11.UserRef= this.UserEmail;




var doc:any={};
    var path = 'Companies/' + this.CompanyRef + '/Snags/' + Ref + '/Responses/'+ responseref+'/Docs';
    this.genService.create(path,file11).subscribe(res =>{


    });




    


  }

  // getSnagResponseFiles = async (ref: string,responseref:string) => {

  //   var path = 'Companies/' + this.CompanyRef + '/Snags/' + ref + '/Responses/'+responseref+'/Docs';
  //   this.genService.getAll(path).subscribe(res => {
  //     this.SnagResponseFiles = res;
  //   });
  //   return this.SnagResponseFiles;
  // };
 //--------------------------------------------------------------------Incidents----------------------------------------------------------------------
    async UploadFileToStorageIncident(file: any, incidentRef: string) {



      const file1 = {
        Name: file.name,
        UserId: this.UserEmail,
        IncidentRef:incidentRef
      };
  
      let url: string;
      const foldname = incidentRef + '/';
      const storage = getStorage();
      const storageRef = ref(storage, 'RDS/IncidentDocs/' + foldname + file.name);
  
      let snapshot1: any;
  
      await uploadBytes(storageRef, file).then((snapshot) => {
        snapshot1 = snapshot;
  
      });
  
      //Showing you somthing
  
      const snapshot = await uploadBytes(storageRef, file);
  
  
  
      await this.getDocDownloadURLIncident(file1, snapshot1, incidentRef);
  
    }
  
  
  
    getDocDownloadURLIncident = async (proj: any, snap: any, rfiRef: string) => {
      await getDownloadURL(snap.ref).then((downloadURL) => {
  
        const url = downloadURL;
        proj.Location = url;
  
      });
      this.createdocIncident(proj, rfiRef);
    };
  
    async createdocIncident(proj: any, incidentRef: string) {
     
      //  var rfiref = "nWHqciiMbaV2JAGleLhf";
      const file11: any = {};
      file11.Name = proj.Name;
      file11.Location = proj.Location;
      file11.UploadTime = new Date();
      file11.UserRef = this.UserEmail;
  
  
      
  var doc:any={};
      var path = 'Companies/' + this.CompanyRef + '/Incidents/' + incidentRef + '/IncidentDocs';
      this.genService.create(path,file11).subscribe(res =>{

  
      });
  
  
  
  
      
  
  
    }
  
    // getIncidentFiles = async (incref: string) => {
  
    //   var path = 'Companies/' + this.CompanyRef + '/Incidents/' + incref + '/IncidentDocs';
    //   this.genService.getAll(path).subscribe(res => {
    //     this.IncidentFiles = res;
    //   });
    //   return this.IncidentFiles;
    // };

//-----------------------------------------------------------------------Document Component Stuff------------------------------------------------------

// async UploadVersions(Base64:string,Path:string){
  
//   var length = Base64.length;
//   var num = length/5;
  
//   var model1:any ={Part:1,Base64:Base64.substr(0,num)}
//  this.genService.create(Path,model1)
//   // var section2 =Base64.substr(num,num)
//   var model2:any ={Part:1,Base64:Base64.substr(num,num)}
//   this.genService.create(Path,model2)
//   var num2 = (num*2) ;
//   // var section3 =Base64.substr(num2,num)
//   var model3:any ={Part:1,Base64:Base64.substr(num2,num)}
//   this.genService.create(Path,model3)
//   var num3 = (num*3) ;
//   // var section4 =Base64.substr(num3,num)
//   var model4:any ={Part:1,Base64:Base64.substr(num3,num)}
//   this.genService.create(Path,model4)
//   var num4 = (num*4) ;

//   var model5:any ={Part:1,Base64:Base64.substr(num4,num)}
//   this.genService.create(Path,model5)
//   //var combined = section1 +section2+section3+section4+section5
 

  







// }

SearchFile(file1:any,path:string,Base64:string,FolderName:string,projref:string,compname:string){
  
  var versions:any[]=[];
  var users:any[]=[];
  
  let file2 = {
    Name: file1.name,
    UserRef :this.UserEmail ,
    Version : versions,
    UserId:users,
    Folder: false
   }

  var file:any ={};
  
   
this.genService.getAllWhere(path,'Name','==',file1.name).subscribe(res =>{
  
file = res[0];
if(file){
  users = file.UserId;
  versions = file.Version;
  users.push(file.UserRef)
  versions.push(file.Location)
  var len = versions.length
  file1.Name = len +" "+ file1.name
  file2.Name = file1.name;
}else{
  
  file1.Name = file1.name;

}
},(error)=>{
  console.error(error);

  
},() =>{
  
  //this.UploadFileToStorage(file1,path,Base64,FolderName,file2,projref,compname);

  
})
return this.UploadFileToStorage(file1,path,Base64,FolderName,file2,projref,compname);;
}

// file = res[0];
// if(file){
//   users = file.UserId;
//   versions = file.Version;
//   users.push(file.UserRef)
//   versions.push(file.Location)
//   var len = versions.length
//   file1.Name = len +" "+ file1.name
//   file2.Name = file1.name;
// }


//GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG---GARETH TEST---GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG



async UploadFileToStorage(file:any,docpath1:string,Base64:string,FolderName:string,file1:any,projref:string,compname:string){
  
    this.User.push(this.UserEmail);
    this.Version =Base64;
  
    // let file1 = {
    //   Name: file.name,
    //   UserRef :this.User ,
    //   Version : this.Version,
    //   Folder: false
    //  }
  
    var url:string
    var foldname = FolderName +"/"
    const storage = getStorage();
    
  const storageRef = ref(storage,compname+'/'+foldname + file.name);
  
  var snapshot1:any;
  
   await uploadBytes(storageRef, file).then((snapshot) => {
       snapshot1 = snapshot;
       
  
    });
  
    //Showing you somthing
  
   // let snapshot = await uploadBytes(storageRef, file);
  
    await getDownloadURL(snapshot1.ref).then((downloadURL) => {
  
      var url = downloadURL;
      
       file1.Location = url;
   
     }); 
  
  
    //await this.getDocDownloadURL(file1,docpath1,snapshot1,projref);
  return this.createdoc(file1,docpath1,projref)
  }
  
  
  
  getDocDownloadURL = async (proj: any,currentpath:string,snap:any,projref:string) =>{
    await getDownloadURL(snap.ref).then((downloadURL) => {
  
     var url = downloadURL;
      proj.Location = url;
  
    }); 
    this.createdoc(proj,currentpath,projref)
  }

  fileAlreadyExists = (proj: any,currentpath:string): Promise<any | null> =>{
    return new Promise((resolve, reject) => {
      var doc:any = {};
      
      this.genService.getAllWhere(currentpath,"Name","==",proj.Name).subscribe(res => {
          doc = res[0]
          
          
      },(error) =>{
        console.error(error)
      },() => {
        resolve(doc)
      })
     })
  }
  async createdoc(proj: any,currentpath:string,projref:string)
  {
    
   var docpath:any;
      let AlreadyExists:any ={};
  var file11:any = {};
     file11.Name = proj.Name;
     file11.Location = proj.Location;
     file11.UserRef = this.accountService.UserDetails.MainUserRef
     file11.Folder = proj.Folder;
     file11.UploadTime = new Date()
     var UserId = [
       UserId= this.UserEmail
     ];
     var docref = "";
  file11.UserId= proj.UserId;
      currentpath 
     if(currentpath==null){
         var path1 = 'Companies/' + this.CompanyRef +'/Projects/'+projref+ '/Documents';
         
         AlreadyExists = await this.fileAlreadyExists(proj,path1);
    //  this.genService.getAllWhere<DocumentFB>(path1,"Name","==",proj.Name).subscribe(res => {
    //     this.docfile = res;
    // var doc =   this.docfile.find(obj => obj.Name === proj.Name);
    // docref = doc.id
    //  })
  
      var array = [];
  
        if(AlreadyExists){
          
          array=AlreadyExists.Version;
          array.push( proj.Version[0]);
          
          file11.Version=array;
          var userarray:any[]=[];
          userarray = AlreadyExists.userId;
          userarray.push(AlreadyExists.UserRef)
          file11.userId = userarray
          file11.Version.push(proj.Location)

         var path2  = 'Companies/' + this.CompanyRef +'/Projects/'+projref+ '/Documents';
         this.viewdoc = (file11);
         this.genService.updatedoc(AlreadyExists.path,file11).subscribe(res =>{
           
           
docpath = AlreadyExists.path +'/Versions';
         })
          
        }else{
          file11.Version = proj.Version[0];
         
          var path3  = 'Companies/' + this.CompanyRef +'/Projects/'+projref+ '/Documents';
          
          this.genService.create(path3,file11).subscribe(res =>{
              docpath ='Companies/' + this.CompanyRef +'/Projects/'+projref+ '/Documents/'+res.id+'/Versions';
          })
        }
  
  
     }else{
        //var path = 'Companies/' + this.CompanyRef +'/Documents';
      //   this.genService.getAllWhere<DocumentFB>(path,"Name","==",proj.Name).subscribe(res => {
      //      this.docfile = res;
      //  var doc =   this.docfile.find(obj => obj.Name === proj.Name);
      //  docref = doc.id
      //   })
        AlreadyExists = await this.fileAlreadyExists(proj,currentpath);
  
      if(AlreadyExists){
        
        array=AlreadyExists.Version;
        // array.push( proj.Version[0]);
        array.push(AlreadyExists.Location)
        file11.Version = array;
        file11.UserId = AlreadyExists.UserId;
        file11.Folder= false;
        file11.UserId.push(AlreadyExists.UserRef);
        
        this.genService.updatedoc(AlreadyExists.path,file11);
        
        AlreadyExists.Version=array;
        var userarray:any[]=[];
        userarray = AlreadyExists.UserId;
        
        
        AlreadyExists.userId = userarray
        
        // this.genService.updatedoc(AlreadyExists.path,AlreadyExists).subscribe(res =>{

        // })
        
      }else{
        file11.Version = proj.Version;
        
        this.genService.create(currentpath,file11).subscribe(res =>{

        })
        
      }
 // this.UploadVersions(docpath,this.Version)
  
  }
  return file11;
}
  createFolderInFolder(proj: Folder,path:string)
  {
  
  
    //add new folder to storage
    // const table = collection(this.firestore, 'Companies/' + compRef +'/Documents');
    var pro:any={};
    var path2:string;
  var path1 = path +'/Documents';
  this.genService.create(path1,proj).subscribe(res => {
     path2 = path +'/Documents/'  + res.id + '/Documents' ;
     pro = {
      PlaceHolder:"No use"
    }
    // return  this.genService.create(path2,pro).subscribe(res => { })
    
   })
   return from(this.db.collection(`${path2}`).add(pro));
  }

  createFolderLevel1(proj: Folder,ProjectRef)
  {
  
    
    //add new folder to storage
    
    var path =  'Companies/' + this.CompanyRef +'/Projects/'+ProjectRef+ '/Documents';
    return from(this.db.collection(`${path}`).add(proj));

  }
  
  
  createFolder(proj: Folder,currentdocpath:string)
  {
  
    var path= currentdocpath +'/Documents' ;
    this.genService.create(path,proj).subscribe(res => {

    });
  }
  

//Get Files and folder list in Documents

   getFolder(Folderpath:string){
    
    //var path = 'Companies/'+this.CompanyRef +'/Projects/'+projref+ '/Documents';
    var data:any=[];
  
    return this.db.collection(`${Folderpath}`).get().pipe(map(result => this.createArray(result)));

  }

  getDocsByParam(collection, getParam:string, paramValue:string ) {
    var docRef = this.db.collection(collection).ref;
    return docRef.where(getParam, '==', paramValue).get();
}



  async getFolderLevelPromise(currentdocpath:string){
  return await this.getFolderLevel(currentdocpath);
}

   getFolderLevel = (currentdocpath:string): Promise<any | null> =>{
    return new Promise((resolve, reject) => {
    var currentdocpath1 = "";
    this.Folders1.length = 0;
    //var path = currentdocpath + '/Documents';
    var path = currentdocpath;

    
  this.genService.getAll(path).subscribe(res => {
      this.Folders1 = res;
      

    },(error) =>{
      console.error(error)
    },() => {
      resolve(this.Folders1);
    })
    
  }) 
  }
  
  
  // getFolderReload = (currentdocpath:string): Promise<any | null> =>{
  //   return new Promise((resolve, reject) => {
  //   var currentdocpath1 = "";
  //   this.Folders1.length = 0;
  //   //var path = currentdocpath + '/Documents';
  //   var path = currentdocpath;

    
  // this.genService.getAll(path).subscribe(res => {
  //     this.Folders1 = res;
      

  //   },(error) =>{
  //     console.error(error)
  //   },() => {
  //     resolve(this.Folders1);
  //   })
    
  // }) 
  // }
  
   getFolderReload(currentdocpath:string){
  
    this.Folders1.length = 0;
    return this.db.collection(`${currentdocpath}`).get()
    .pipe(map(result => this.createArray(result)));

  }


  
  getFiles(filename:string,CompanyName:string){
    const storage = getStorage();
    var location:string;
    const storageRef = ref(storage,CompanyName+'/Documents/'+filename );
    var data:any={};
    getDownloadURL(storageRef).then(res =>{
  
     data = res
    })
    return data;
  }


  getDocDownloadBase64(file:any){
    const storage = getStorage();
    getDownloadURL(ref(storage, file))
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'
    
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (file) => {
          const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
    
        // Or inserted into an <img> element
        const img = document.getElementById('myimg');
        img.setAttribute('src', url);
      })
      .catch((error) => {
        // Handle any errors
      });
  }

delete(name){
  
  const storage = getStorage();
  const desertRef = ref(storage, name);
  var result:any;
//const storage = getStorage();
  // Delete the file
  deleteObject(desertRef).then((re) => {
    // File deleted successfully
    result = re;
  }).catch((error) => {
    result = error;
  });
  return result;
}


 //-------------------------------------------------------------------createGet360Camera----------------------------------------------------------------------------
 async Get360Camera(
  file: Blob, 
  path: string, 
  path1:string, 
  uniqueSafeName:string, 
  AreaRef :string,
  NormDate:string,
  
  ) 
  {
    
    
  if (file !== undefined){
//get the data that was sent to this service and add it to an object
  const file1 = {
    Name: uniqueSafeName,
    UserId: this.UserEmail,
    RfiRef: AreaRef,
    

  };

  //create storage location for firebase storage
  let url: string;
  const foldname = AreaRef + '/';
  const storage = getStorage();
  const storageRef = ref(storage, path);

  let snapshot1: any;
  
  //upload base64 to firebase
  await uploadBytes(storageRef, file).then((snapshot) => {
    snapshot1 = snapshot;

  });

  await this.Get360CameraURL(file1,  snapshot1, path1,NormDate,path);

}

}  



Get360CameraURL = async (file: any, snap: any, path1: string,NormDate: string,path:string) => {

  

  await getDownloadURL(snap.ref).then((downloadURL) => {

    
//getting the url path of uploaded documnet
    const url = downloadURL;
    file.Location = url;

  });
  
  this.createGet360Camera(file, path1, NormDate,path);
};

async createGet360Camera(file: any, path1: string,NormDate: string,path:string) {
//adding file details and url to firestore.


var model:any ={};
let timestamp = Date.now();  


debugger
    
  model.SubmittedDate = NormDate;
  model.originalName = NormDate + '.jpg'
  model.storagePath = path
  model.timestamp = timestamp
  model.downloadURL =   file.Location
  model.UserRef = file.UserId

          this.genService.create((path1), model).subscribe((res) => {
           

          },(er) =>{
            console.error();
            
          },() =>{
          });
  }


//------------------------------------------------------------------End of Document Component------------------------------------------------------------
createArray<T>(results) {
  return <T[]>results.docs.map((snap) => {
    return {
      id: snap.id,
      path: snap.ref.path,
      ...<any>snap.data()
    }
  });
}

getAll<T>(collection: string, orderBy?: string): Observable<T[]> {
  if(orderBy){
    return this.db.collection(`${collection}`, x => x.orderBy(orderBy)).get()
    .pipe(map(result => this.createArray<T>(result)));
  }
  else{
    return this.db.collection(`${collection}`).get()
    .pipe(map(result => this.createArray<T>(result)));
  }
}
}
