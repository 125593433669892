import { Component, OnInit } from '@angular/core';
import { User } from './models/user';
import { AccountService } from './services/account.service';

import { Observable } from 'rxjs';
import { MenuController, PopoverController } from '@ionic/angular';
import { NotificationsPage } from './notifications/notifications.page';
import { GenericCRUDService } from './services/gencrud.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {


  users: any;
  CurrentUsername: string;
  CurrentUserId: number;
  CompanyId: number;
  isUserLoggedIn: boolean = false;
  CompanyRef: string;
  UserEmail: string;
  projectList: any[] = [];
  genpermission: string;
  constructor(
    private accountService: AccountService,
    public popoverController: PopoverController,
    private menu: MenuController,
    public genService: GenericCRUDService,
    private router: Router
  ) {
    this.genService.displayMenuItems();
  }

  ngOnInit() {
  // this.genService.showMenu = true;
  }




  setCurrentUser() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    // this.accountService.setCurrentUser(user);
    this.CurrentUserId = user.userId;
    this.CompanyId = user.companyId;
  }

  async logout() {
    await this.accountService.logout();
    this.router.navigateByUrl('/login');
    this.genService.showMenu = false;
  }

  async showNotifications(ev: any) {
    
    const popover = await this.popoverController.create({
      component: NotificationsPage,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    await popover.present();
  }
}
